import { ReactElement, ReactNode } from "react";
export const SNAPSHOT_VEHICLES_LOADING = "SNAPSHOT_VEHICLES_LOADING";
export const SNAPSHOT_VEHICLES_FAILURE = "SNAPSHOT_VEHICLES_FAILURE";
export const SNAPSHOT_VEHICLES_SUCCESS = "SNAPSHOT_VEHICLES_SUCCESS";

export type SnapshotVehicles = {
  id: string;
  AssetNumber: string;
  MCUFirmwareVersion: string;
  DCMFirmwareVersion: string;
  LatestMCUFirmwareVersion: string;
  LatestDCMFirmwareVersion: string;
  Date: string;
  DeviceName: string;
  StealthBatteryAmps: number;
  StealthBatteryVolts: number;
  SolarAmps: number;
  ShorelineVolts: number;
  SystemTemperature: number;
  StateOfCharge: number;
  OEMBatteryVoltage: number;
  FuelLevel: number;
  BMSMaxCellVolts: number;
  RSSI: number;
  BMSPackVolts: number;
  SystemState: number;
  DaylightSignal: boolean;
  ActiveLoad: boolean;
  SolarVolts: number;
};

export interface SnapshotVehicleLoading {
  type: typeof SNAPSHOT_VEHICLES_LOADING;
}

export interface SnapshotVehicleFailure {
  type: typeof SNAPSHOT_VEHICLES_FAILURE;
}

export interface SnapshotVehicleSuccess {
  type: typeof SNAPSHOT_VEHICLES_SUCCESS;
  payload: SnapshotVehicles;
}

export type SnapshotVehicleDispatchTypes =
  | SnapshotVehicleLoading
  | SnapshotVehicleFailure
  | SnapshotVehicleSuccess;
